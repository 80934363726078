<script>
export default {
  name: "erjirenyuan",
  data() {
    return {
      regname:regname,
      renyuan_list: [],
      create_data: {
        id: '0',
        space: '2',
        name: '',
        account: '',
        password: '',
        serial_num: ''
      }
    }
  },
  mounted() {
    this.getrenyuanlist()
  },
  methods: {
    saverenyuanlist(data) {
      this.$sa0.post({
        url: this.$api('二级：保存人员列表'),
        data: data,
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.getrenyuanlist()
            this.create_data = {
              id: '0',
              name: '',
              space: '2',
              account: '',
              password: '',
              serial_num: ''
            }
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    getrenyuanlist() {
      this.$sa0.post({
        url: this.$api('二级：人员列表'),
        data: {},
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.renyuan_list = response.data.list.map(item => {
              item.space = '2'
              return item
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    }
  }
}
</script>
<template>
  <div>
    <table class="layui-table" lay-size="sm">
      <thead>
      <tr>
        <th>地区</th>
        <th>名称</th>
        <th>账号</th>
        <th>密码</th>
        <th>编号</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <a-select v-model="create_data.space">
            <a-select-option value="2">{{regname}}</a-select-option>
          </a-select>
        </td>
        <td>
          <a-input v-model="create_data.name"></a-input>
        </td>
        <td>
          <a-input v-model="create_data.account"></a-input>
        </td>
        <td>
          <a-input v-model="create_data.password"></a-input>
        </td>
        <td>
          <a-input v-model="create_data.serial_num"></a-input>
        </td>
        <td>
          <a-space>
            <a-button @click="saverenyuanlist(create_data)" type="primary">新建</a-button>
          </a-space>
        </td>
      </tr>
      <tr v-for="(item,key) in renyuan_list" :key="key">
        <td>
          <a-select v-model="item.space">
            <a-select-option value="2">{{ regname }}</a-select-option>
          </a-select>
        </td>
        <td>
          <a-input v-model="item.name"></a-input>
        </td>
        <td>
          <a-input v-model="item.account"></a-input>
        </td>
        <td>
          <a-input v-model="item.password"></a-input>
        </td>
        <td>
          <a-input v-model="item.serial_num"></a-input>
        </td>
        <td>
          <a-space>
            <a-button @click="saverenyuanlist(item)" type="primary">保存</a-button>
          </a-space>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>

</style>